// $primary: #ffd100;
// $secondary: #000;
// $white: #fff;
// $light: #f7f7f7;
// $font-weight-bolder: 800;

.container {
  max-width: 1200px !important;
  margin: 0 auto;
}

/* Customize bootstrap's default value above this line */
@import '~bootstrap/scss/bootstrap';
