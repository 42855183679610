.our-partners {
  margin-top: -1rem;
  position: relative;
  @media only screen and (max-width: 767px) {
    margin: 0;
  }
  .container-fluid {
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .our-partners-inner {
    display: flex;
    justify-content: space-between;
    background: var(--primary-color);
    padding: 2rem;
    @media only screen and (max-width: 767px) {
      padding: 1rem;
    }

    &__left-logo {
      width: 45%;
      img {
        max-width: 100%;
      }
    }

    &__right-content {
      width: 50%;
      @media only screen and (max-width: 767px) {
        h3 {
          display: none;
        }
        p {
          display: none;
        }
      }

      .learn-more {
        display: none;
        @media only screen and (max-width: 767px) {
          display: block;

          color: #707070;
          width: 160px;
          margin: 0 auto;
          padding: 0.675rem;
          background: white;
          border-radius: 60px;
          text-align: center;

          &:hover {
            text-decoration: none;
            background: var(--primary-color);
            color: #000;
          }
        }
      }
    }
  }
}
