.progress-step-personel {
  background: var(--primary-color);
  padding: 0 3rem 1rem;

  @media only screen and (max-width: 767px) {
    padding: 0 1rem 1rem;
  }

  &__title {
    text-align: center;
    padding-top: 3rem;
    h2 {
      font-size: 3.75rem;
      color: var(--white);
      font-weight: 400;

      @media only screen and (max-width: 767px) {
        font-size: 2rem;
      }

      span {
        font-weight: 300;
      }
    }
    p {
      width: 65%;
      margin: 0 auto;
      font-size: 1rem;
      line-height: 20px;
      @media only screen and (max-width: 767px) {
        width: auto;
        margin: 0 auto;
        font-size: 0.8125rem;
        line-height: 18px;
      }
    }
  }

  %remain-steps {
    &:before {
      content: counter(stepNum);
      font-family: inherit;
      font-weight: 700;
    }
    &:after {
      background-color: #fff;
    }
  }
  .multi-steps {
    display: table;
    table-layout: fixed;
    width: 90%;
    margin: 2rem auto;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    > li {
      counter-increment: stepNum;
      text-align: center;
      display: table-cell;
      position: relative;
      // color: $brand-primary;
      z-index: 12;
      font-size: 0.75rem;
      font-weight: 600;
      white-space: pre-line;
      @media only screen and (max-width: 767px) {
        font-size: 0.45rem;
      }

      &:before {
        content: '\f00c';
        content: '\2713;';
        content: '\10003';
        content: '\10004';
        content: '\2713';
        display: block;
        margin: 0 auto 4px;
        background-color: #fdcd2e;
        width: 42px;
        height: 42px;
        line-height: 38px;
        text-align: center;
        font-weight: bold;
        color: #fff;
        font-size: 1rem;
        border: {
          width: 2px;
          style: solid;
          color: #fff;
          radius: 50%;
        }
      }
      &:after {
        content: '';
        height: 2px;
        width: 76%;
        background-color: #fff;
        position: absolute;
        top: 20px;
        left: 62%;
        z-index: -1;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }

      &.is-active {
        @extend %remain-steps;

        &:before {
          // background-color: #fdcd2e;
          border-color: #fff;
          color: transparent;
          // visibility: hidden;
          background: url(../../assets/badge.svg) #fdcd2e;
          background-size: 24px;
          background-repeat: no-repeat;
          background-position-x: center;
          background-position-y: center;
        }

        ~ li {
          @extend %remain-steps;
          &:before {
            background-color: #000;
            border-color: #fff;
            font-size: 1rem;
          }
        }
      }
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid white;
    width: 50;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}
