.content-grid-homepage {
  &__wrapper-card {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      border-top: 8px solid var(--primary-color);
    }

    .card {
      border: none;
      border-radius: 0;
      padding: 1rem;
      img {
        width: 60px;
        margin: 1rem auto;
      }

      .card-body {
        @media only screen and (max-width: 767px) {
          padding: 0;
        }
        .card-title {
          text-align: center;
          @media only screen and (max-width: 767px) {
            font-weight: 400;
            font-size: 1rem;
            line-height: 24px;
          }
        }
      }
    }

    .card:nth-child(1) {
      background: #e3e3e3;

      @media only screen and (max-width: 767px) {
        width: 50%;
      }
    }

    .card:nth-child(2) {
      background: #d3cfcf;
      @media only screen and (max-width: 767px) {
        width: 50%;
      }
    }

    .card:nth-child(3) {
      background: #e3e3e3;
      @media only screen and (max-width: 767px) {
        width: 50%;
        order: 2;
      }
    }

    .card:nth-child(4) {
      background: #d3cfcf;
      @media only screen and (max-width: 767px) {
        width: 50%;
      }
    }
  }
}
