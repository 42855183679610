.choose-window {
  &__block-wrapper {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      display: none;
    }

    &__left-content {
      width: 50%;
      background: #f1ebeb;

      &__wrapper-text {
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        h1 {
          font-size: 4.5rem;
          color: #707070;
        }

        p {
          color: #707070;
        }

        a {
          background: #707070;
          width: 160px;
          margin: 0 auto;
          padding: 0.675rem;
          color: white;
          border-radius: 60px;
        }
        a:hover {
          text-decoration: none;
          background: var(--primary-color);
          color: #000;
        }
      }
    }

    &__right-content {
      width: 50%;
      background: #707070;

      &__wrapper-text-right {
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: var(--white);

        h1 {
          font-size: 4.5rem;
          font-weight: 700;
        }

        a {
          background: #ffff;
          width: 160px;
          margin: 0 auto;
          padding: 0.675rem;
          color: #707070;
          border-radius: 0;
        }
        a:hover {
          text-decoration: none;
          background: var(--primary-color);
          color: #000;
        }
      }
    }
  }

  .choose-on-mobile {
    display: none;
    @media only screen and (max-width: 767px) {
      display: block;
      // padding-top: 1.3rem;

      .accordion {
        padding-top: 1.3rem;
        .card {
          border: none;
          text-align: center;
          &:nth-child(1) {
            position: relative;
            z-index: 7;
            margin-top: -1.3rem;
            padding-bottom: 1.3rem;
            background: transparent;
            .card-header {
              display: none;
            }
            .card-body {
              padding: 5rem 0;
              background: #e3e3e3;
              margin-top: -1.3rem;
            }
          }

          &:nth-child(2) {
            position: relative;
            z-index: 8;
            margin-top: -1.3rem;
            background: #e3e3e3;
            .card-header {
              background: #707070;
              border-radius: 15px 15px 0 0;
              padding: 1rem 0.5rem;
              button {
                border: none;
                background: transparent;
                color: white;
                display: flex;
                h3 {
                  font-size: 3rem;
                  font-weight: 700;
                  margin-bottom: 0;
                }
                p {
                  font-size: 00.8125rem;
                }
                img {
                  margin-right: 15px;
                }
              }
              &:before {
                content: '';
                position: absolute;
                width: 40px;
                height: 40px;
                background: #e3e3e3;
                border-radius: 50%;
                left: 50%;
                transform: translate(-50%, -38px);
              }
              &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-left: 24px solid transparent;
                border-right: 24px solid transparent;
                border-top: 20px solid #707070;
                left: 50%;
                transform: translate(-50%, 10px);
              }
            }

            .card-body {
              padding: 4rem;
              background: #e3e0e0;

              .ctc-button {
                background: var(--primary-color);
                padding: 0.5rem 1rem;
                color: #000;
                font-weight: 600;
              }
            }
          }

          &:nth-child(3) {
            position: relative;
            z-index: 9;
            margin-top: -1.3rem;
            background: transparent;
            .card-header {
              background: #f2ecec;
              border-radius: 15px 15px 0 0;
              padding: 1rem 0.5rem;
              button {
                border: none;
                background: transparent;
                color: #707070;
                display: flex;
                h3 {
                  font-size: 3rem;
                  font-weight: 500;
                  margin-bottom: 0;
                }
                p {
                  font-size: 00.8125rem;
                }
                img {
                  margin-right: 15px;
                }
              }

              &:before {
                content: '';
                position: absolute;
                width: 40px;
                height: 40px;
                background: #707070;
                border-radius: 50%;
                left: 50%;
                transform: translate(-50%, -38px);
              }
              &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-left: 24px solid transparent;
                border-right: 24px solid transparent;
                border-top: 20px solid #f2ecec;
                left: 50%;
                transform: translate(-50%, 10px);
              }
            }

            .card-body {
              padding: 4rem;
              background: #e3e0e0;

              .ctc-button {
                background: var(--primary-color);
                padding: 0.5rem 1rem;
                color: #000;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
