.setup-business {
  display: flex;
  justify-content: space-between;
  margin-top: 1.3rem;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  &__left-content {
    width: 73%;
    @media only screen and (max-width: 767px) {
      width: 100% !important;
    }

    .have-an-account {
      background: var(--primary-color);
      padding: 1rem 3rem;
      @media only screen and (max-width: 767px) {
        padding: 1rem 1rem !important;
      }

      .form-label {
        margin-bottom: 1.3rem;
        color: #fff;
        font-weight: 500;
      }

      .have-an-account-row {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
          flex-direction: column;
        }

        .form-group {
          width: 39%;
          margin-bottom: 0;

          @media only screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 1rem;
          }

          .form-control {
            height: 2.5rem;
            padding: 1rem;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.5;
            border: none !important;
            color: #495057;
            border-radius: 0;
          }
        }
        .login-button {
          width: 18%;
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
          button {
            width: 100%;
            height: 2.5rem;
            border-radius: 0;
            background: #000;
            border: none;
          }
        }
      }
    }

    .business-account-setup {
      background: var(--primary-color);
      padding: 1rem 3rem;
      margin-top: 1.3rem;

      @media only screen and (max-width: 767px) {
        padding: 1rem 1rem !important;
      }

      .form-label {
        margin-bottom: 1.3rem;
        color: #fff;
        font-weight: 500;
        span {
          color: #000;
        }
      }

      &__row1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 767px) {
          flex-direction: column;
        }

        &__cols {
          width: 32%;

          @media only screen and (max-width: 767px) {
            width: 100%;
          }

          .form-control {
            height: 2.5rem;
            padding: 1rem;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.5;
            border: none !important;
            color: #495057;
            border-radius: 0;
          }
        }
      }

      &__row2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @media only screen and (max-width: 767px) {
          flex-direction: column;
        }

        &__cols {
          width: 49%;
          @media only screen and (max-width: 767px) {
            width: 100% !important;
          }
          .form-control {
            height: 2.5rem !important;
            padding: 1rem;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.5;
            border: none !important;
            color: #495057;
            border-radius: 0 !important;
          }
        }
      }

      &__row2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media only screen and (max-width: 767px) {
          flex-direction: column;
        }

        &__cols {
          width: 49%;
          @media only screen and (max-width: 767px) {
            width: 100% !important;
          }
          .form-control {
            height: 2.5rem !important;
            padding: 1rem;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.5;
            border: none !important;
            color: #495057;
            border-radius: 0 !important;
          }
        }
      }

      &__row3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media only screen and (max-width: 767px) {
          flex-direction: column;
        }

        &__cols {
          width: 78%;
          @media only screen and (max-width: 767px) {
            width: 100%;
          }

          &:last-child {
            width: 20%;

            @media only screen and (max-width: 767px) {
              width: 100% !important;
            }
          }

          .form-group {
            margin-bottom: 0;
            .form-control {
              height: 2.5rem !important;
              padding: 1rem;
              font-size: 0.75rem;
              font-weight: 400;
              line-height: 1.5;
              border: none !important;
              color: #495057;
              border-radius: 0 !important;
            }
          }
        }
        .find-address {
          @media only screen and (max-width: 767px) {
            width: 100% !important;
            margin-top: 1.3rem;
          }
          button {
            height: 2.5rem;
            border-radius: 0;
            background: grey;
            border: none;

            @media only screen and (max-width: 767px) {
              width: 100% !important;
            }
          }
        }
      }

      .group-buttons {
        button {
          height: 2.5rem;
          border-radius: 0;
          background: #000;
          border: none;
          margin: 0.5rem;

          &:first-child {
            background: grey;
          }
        }
      }

      .add-check {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        .styled-checkbox {
          position: absolute; // take it out of document flow
          opacity: 0; // hide it

          & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
          }

          // Box.
          & + label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: white;
          }

          // Box hover
          &:hover + label:before {
            background: #000;
          }

          // Box focus
          &:focus + label:before {
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
          }

          // Box checked
          &:checked + label:before {
            background: #000;
          }

          // Disabled state label.
          &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
          }

          // Disabled box.
          &:disabled + label:before {
            box-shadow: none;
            background: #ddd;
          }

          // Checkmark. Could be replaced with an image
          &:checked + label:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 9px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
              4px -8px 0 white;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  &__next-day-delivery {
    width: 25%;
    @media only screen and (max-width: 767px) {
      width: 100% !important;
    }

    .card {
      width: 100%;
      border-radius: 0;
      border: none;
      @media only screen and (max-width: 767px) {
        margin-top: 1.3rem;
      }

      .card-body {
        padding-bottom: 0;
        .card-title {
          font-size: 0.75rem;
          font-weight: 600;
          width: 75%;
          margin: 0 auto 1rem;
        }
        .card-text {
          font-size: 1.5rem;
          color: #505050;
          margin-bottom: 0;
          line-height: 38px;
        }
        .card-text-color {
          font-size: 2rem;

          margin-bottom: 0;
        }

        button {
          transform: translate(0, 50%);
          border-radius: 3.75rem;

          border: 2px solid white;
          font-size: 0.75rem;
          font-weight: 600;
          padding: 0.5rem 1.5rem;
        }
      }

      .highlights-tooltip {
        display: flex;
        justify-content: center;

        &__inner {
          position: absolute;
          z-index: 1;

          width: 75%;
          text-align: center;
          color: white;
          // transform: translate(0, -50%);
          height: 45px;
          line-height: 45px;
          font-weight: 600;

          &:after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;

            left: 50%;
            top: 40px;
            transform: translate(-50%, 0);
          }
        }
      }

      .partial-bg {
        height: 135px;
        width: 100%;
        position: absolute;
        top: 0;
      }

      img {
        width: 160px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        padding-top: 3rem;

        &:before {
          content: ' ';
          position: absolute;
          background: grey;
          height: 100px;
          width: 100%;
        }
      }

      .card-footer {
        border-radius: 0;
        height: 70px;
      }
    }

    .gold {
      .card-body {
        .card-text-color {
          color: #6275db;
        }

        button {
          background: #6275db;
        }
      }

      .highlights-tooltip {
        &__inner {
          background: #6275db;

          &:after {
            border-top: 15px solid #6275db;
          }
        }
      }

      .partial-bg {
        background: #6275db;
      }

      .card-footer {
        background: #6275db;
      }
    }

    .silver {
      .card-body {
        .card-text-color {
          color: #59c7b2;
        }

        button {
          background: #59c7b2;
        }
      }

      .highlights-tooltip {
        &__inner {
          background: #59c7b2;

          &:after {
            border-top: 15px solid #59c7b2;
          }
        }
      }

      .partial-bg {
        background: #59c7b2;
      }

      .card-footer {
        background: #59c7b2;
      }
    }

    .bronze {
      .card-body {
        .card-text-color {
          color: #f57d05;
        }

        button {
          background: #f57d05;
        }
      }

      .highlights-tooltip {
        &__inner {
          background: #f57d05;

          &:after {
            border-top: 15px solid #f57d05;
          }
        }
      }

      .partial-bg {
        background: #f57d05;
      }

      .card-footer {
        background: #f57d05;
      }
    }
  }
}
