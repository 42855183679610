.package-details {
  background: var(--primary-color);
  padding: 0 3rem 1rem;
  @media only screen and (max-width: 767px) {
    padding: 0 1rem 1rem;
  }
  &__form-row {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    &__form-col {
      width: 35%;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      &:last-child {
        width: 22%;
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }

      label {
        font-size: 0.75rem;
        font-weight: 500;
      }

      .form-control {
        height: 2.5rem;
        // padding: 1rem;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        border: none !important;
        color: #495057;
        border-radius: 0;
      }
      .css-yk16xz-control {
        height: 2.5rem;
        border-radius: 0;
        border: none;
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }

  &__form-row_child {
    display: flex;
    justify-content: space-between;

    &__form-col {
      width: 18%;

      label {
        font-size: 0.75rem;
        font-weight: 500;
      }

      .form-control {
        height: 2.5rem;
        // padding: 1rem;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        border: none !important;
        color: #495057;
        border-radius: 0;
      }
    }
  }

  .add-button {
    display: flex;
    align-items: center;
    width: 30%;

    @media only screen and (max-width: 767px) {
      width: 75%;
    }

    span {
      font-size: 2rem;
      font-weight: 600;
    }
    button {
      margin-left: 1rem;
      border-radius: 4rem;
      height: 2.5rem;
      font-size: 0.75rem;
      padding: 0 1rem;
      font-weight: 500;
    }
  }

  .add-check {
    display: flex;
    align-items: center;

    .styled-checkbox {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        @media only screen and (max-width: 767px) {
          font-size: 0.8125rem;
        }
      }

      // Box.
      & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
      }

      // Box hover
      &:hover + label:before {
        background: #000;
      }

      // Box focus
      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      // Box checked
      &:checked + label:before {
        background: #000;
      }

      // Disabled state label.
      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      // Disabled box.
      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
      }
    }
  }

  .button-quote {
    button {
      border-radius: 0;
      background: #000;
      font-size: 0.75rem;
      font-weight: 500;
      padding: 0.5rem 1rem;
    }
  }
}
