.business-quote {
  .container {
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .business-quote-outer {
    // background: var(--primary-color);
    // padding: 3rem;
    width: 1040px;
    margin: 0 auto;

    @media only screen and (max-width: 767px) {
      width: auto;
    }
  }
}
