header {
  .top-header {
    background: var(--primary-color);
    &__right-text {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }
  }

  .primary-header {
    padding: 1rem 0;
    background: white;
    .navbar {
      padding: 0;
      .navbar-brand {
        img {
          @media only screen and (max-width: 767px) {
            max-width: 200px;
            margin-left: 0.5rem;
          }
        }
      }
      .navbar-toggler {
        margin-right: 1rem;
      }

      #basic-navbar-nav {
        @media only screen and (max-width: 767px) {
          background: rgb(243, 243, 243);
          position: absolute;
          width: 100%;
          z-index: 9999;
          top: 60px;
        }

        .navbar-nav {
          .nav-link {
            color: #000;
            font-size: 0.8125rem;
            font-weight: 600;
            padding-left: 1rem;
            padding-right: 1rem;

            @media only screen and (max-width: 767px) {
              border-bottom: 1px solid #e6e6e6;
              padding: 1rem;
            }
          }
        }
        button {
          margin-left: 2rem;
          font-weight: 600;
          @media only screen and (max-width: 767px) {
            margin: 1rem;
          }
        }
      }
    }
  }
}
