.price-delivery {
  background: var(--primary-color);
  padding: 3rem 3rem 1rem;
  @media only screen and (max-width: 767px) {
    padding: 3rem 1rem 1rem;
  }
  &__card {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .card {
      width: 32%;
      border-radius: 0;
      border: none;
      @media only screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 3rem;
      }

      .card-body {
        padding-bottom: 0;
        .card-title {
          font-size: 0.75rem;
          font-weight: 600;
          width: 75%;
          margin: 0 auto 1rem;
        }
        .card-text {
          font-size: 1.5rem;
          color: #505050;
          margin-bottom: 0;
          line-height: 38px;
        }
      }

      .partial-bg {
        background: #f0f0f0;
        height: 135px;
        width: 100%;
        position: absolute;
        top: 0;
      }

      .wrapper-img-card {
        background: white;
        img {
          width: 160px;
          margin: 0 auto;
          position: relative;
          z-index: 1;
          padding-top: 3rem;

          &:before {
            content: ' ';
            position: absolute;
            background: grey;
            height: 100px;
            width: 100%;
          }
        }
      }

      &:nth-child(1) {
        .card-body {
          .card-text-color {
            font-size: 2rem;
            color: #6275db;
            margin-bottom: 0;
          }

          button {
            transform: translate(0, 50%);
            border-radius: 3.75rem;
            background: #6275db;
            border: 2px solid white;
            font-size: 0.75rem;
            font-weight: 600;
            padding: 0.5rem 1.5rem;
          }
        }

        .highlights-tooltip {
          display: flex;
          justify-content: center;

          &__inner {
            position: absolute;
            z-index: 1;
            background: #6275db;
            width: 75%;
            text-align: center;
            color: white;
            transform: translate(0, -50%);
            height: 45px;
            line-height: 45px;
            font-weight: 600;

            &:after {
              content: ' ';
              position: absolute;
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-top: 15px solid #6275db;
              left: 50%;
              top: 40px;
              transform: translate(-50%, 0);
            }
          }
        }

        .card-footer {
          background: #6275db;
          border-radius: 0;
          height: 70px;
        }
      }

      &:nth-child(2) {
        .card-body {
          .card-text-color {
            font-size: 2rem;
            color: #59c7b2;
            margin-bottom: 0;
          }

          button {
            transform: translate(0, 50%);
            border-radius: 3.75rem;
            background: #f0f0f0;
            border: 2px solid white;
            font-size: 0.75rem;
            font-weight: 600;
            padding: 0.5rem 1.5rem;
            color: #000;
          }
        }

        .highlights-tooltip {
          display: flex;
          justify-content: center;

          &__inner {
            position: absolute;
            z-index: 1;
            background: #59c7b2;
            width: 75%;
            text-align: center;
            color: white;
            transform: translate(0, -50%);
            height: 45px;
            line-height: 45px;
            font-weight: 600;

            &:after {
              content: ' ';
              position: absolute;
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-top: 15px solid #59c7b2;
              left: 50%;
              top: 40px;
              transform: translate(-50%, 0);
            }
          }
        }

        .card-footer {
          background: #59c7b2;
          border-radius: 0;
          height: 70px;
        }
      }

      &:nth-child(3) {
        .card-body {
          .card-text-color {
            font-size: 2rem;
            color: #f57d05;
            margin-bottom: 0;
          }

          button {
            transform: translate(0, 50%);
            border-radius: 3.75rem;
            background: #f0f0f0;
            border: 2px solid white;
            font-size: 0.75rem;
            font-weight: 600;
            padding: 0.5rem 1.5rem;
            color: #000;
          }
        }

        .highlights-tooltip {
          display: flex;
          justify-content: center;

          &__inner {
            position: absolute;
            z-index: 1;
            background: #f57d05;
            width: 75%;
            text-align: center;
            color: white;
            transform: translate(0, -50%);
            height: 45px;
            line-height: 45px;
            font-weight: 600;

            &:after {
              content: ' ';
              position: absolute;
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-top: 15px solid #f57d05;
              left: 50%;
              top: 40px;
              transform: translate(-50%, 0);
            }
          }
        }

        .card-footer {
          background: #f57d05;
          border-radius: 0;
          height: 70px;
        }
      }
    }
  }

  .group-buttons {
    display: flex;
    justify-content: center;
    margin: 1rem;
    @media only screen and (max-width: 767px) {
      margin: 0 1rem;
    }

    button {
      border-radius: 0;
      font-size: 0.75rem;
      font-weight: 500;
      padding: 0.5rem 1rem;
      margin: 0.5rem;
      &:last-child {
        background: #000;
      }
    }
  }
}
